import Footer from '../../components/Footer';
import StickyHeader2 from '../../components/StickyHeader2';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompanyAsync } from '../../actions/company.action';
import { getCompanyReviewsAsync, getUserReviewsAsync } from '../../actions/review.action';
import UserPageBanner from '../../components/UserPageBanner';
import UserPageReviews from '../../components/UserPageReviews';
import { getUserByIdAsync } from '../../actions/user.action';

function UserPage() {
  const initialState = {
    user: null,
    reviews: [],
    last_page: 1,
    page: 1,
    offset: 0
  };
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoaded(false);
    setState(initialState);
    console.log(id)
    dispatch(getUserByIdAsync(id, (data) => {
      console.log(data)
      setState({
        ...state,
        ...data,
      })
      setLoaded(true);
    }))
  }, [id])

  useEffect(() => {
    if (loaded)
      dispatch(getUserReviewsAsync(id, page, 20, (data) => {
        const { pagination: { data: reviews, last_page, page, offset } } = data;
        setState({
          ...state,
          reviews,
          last_page,
          page,
          offset
        })
      }))
  }, [loaded, page])

  if (!state.user) return null

  return (
    <>
      <StickyHeader2 />
      <main>
        <UserPageBanner state={state} />
        <UserPageReviews setPage={setPage} state={state} />
      </main>
      <Footer />
    </>
  );
}

export default UserPage;
