import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import '../../utils/isotope';
import ReviewCardSmall from "../ReviewCardSmall";

const ReviewsResults = ({ state, handleLoadMore }) => {
    const { reviews, end } = state;

    useEffect(() => {
        let $grid;
        setTimeout(() => {
            $grid = window.$('.isotope-wrapper').isotope({ itemSelector: '.isotope-item', layoutMode: 'masonry' });
        }, 100);
        return () => $grid && $grid.isotope('destroy');
    }, [reviews])

    return (
        <div className="container margin_60_35">
            <div className="isotope-wrapper">
                <div class="row">
                    {
                        reviews.map((review, index) => {
                            return (
                                <div class="col-xl-4 col-lg-6 col-md-6 isotope-item" key={index}>
                                    <ReviewCardSmall review={review} addBottom15 />
                                </div>
                            )
                        })
                    }
                </div>

            </div>

            {
                !end
                    ?
                    <p className="text-center">
                        <Link
                            to="#"
                            onClick={(event) => {
                                event.preventDefault();
                                handleLoadMore()
                            }} className="btn_1 rounded add_top_15"
                        >
                            Load more
                        </Link>
                    </p>
                    :
                    null
            }
        </div>
    )
}

export default ReviewsResults
