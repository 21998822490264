import React from "react";

const ContactHero = () => {
    return (
        <section className="hero_single general">
            <div className="wrapper">
                <div className="container">
                    <h1>Get in Touch with Vanno</h1>
                    <p>Vanno helps grow your business using customer reviews</p>
                </div>
            </div>
        </section>
    )
}

export default ContactHero
