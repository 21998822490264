import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordAsync, updateUserAsync } from "../../actions/user.action";
import { showToast } from "../../utils";
import countries from "../../utils/countries.json"

const EditUser = () => {
    const dispatch = useDispatch();
    const initialPasswords = {
        password: '',
        new_password: '',
        confirm_password: '',
    }
    const { profile } = useSelector(({ User }) => User);
    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        country: '',
        phone: '',
        country_code: '',
    })
    const [passwords, setPasswords] = useState(initialPasswords)

    useEffect(() => {
        if (!profile) return;
        setValues({
            first_name: profile.first_name || '',
            last_name: profile.last_name || '',
            country: profile.country || '',
            phone: profile.phone || '',
            country_code: profile.country_code || '',
        })
    }, [profile])

    if (!profile) return;

    const handleUpdateProfile = () => {
        dispatch(updateUserAsync(values, (data) => {
            showToast("Profile updated successfully", "success")
        }))
    }

    const handleUpdatePassword = () => {
        dispatch(updatePasswordAsync(passwords, (data) => {
            setPasswords(initialPasswords)
            showToast("Password updated successfully", "success")
        }))
    }

    return (
        <div className="col-lg-8">
            <div className="settings_panel">
                <h3>Personal settings</h3>
                <hr />
                <div className="form-group">
                    <label>Edit First Name</label>
                    <input
                        className="form-control"
                        type="text"
                        value={values.first_name}
                        onChange={(event) => setValues({
                            ...values,
                            first_name: event.target.value,
                        })}
                    />
                </div>
                <div className="form-group">
                    <label>Edit Last Name</label>
                    <input
                        className="form-control"
                        type="text"
                        value={values.last_name}
                        onChange={(event) => setValues({
                            ...values,
                            last_name: event.target.value,
                        })}
                    />
                </div>
                <div className="form-group">
                    <label>Edit Photo</label>
                    <div className="fileupload"><input type="file" name="fileupload" accept="image/*" /></div>
                </div>
                <div className="form-group">
                    <label>Edit Country</label>
                    <select
                        className="form-control"
                        value={values.country}
                        onChange={(event) => {
                            const country = countries.filter(e => e.country === event.target.value)[0];
                            console.log(country)
                            if (!country) return;
                            setValues({
                                ...values,
                                country: country.country,
                                country_code: String(country.calling_code)
                            })
                        }}
                    >
                        {
                            countries.map(({ country, calling_code }) => {
                                return <option value={country}>{country}</option>
                            })
                        }
                    </select>
                </div>
                <p className="text-end">
                    <a
                        className="btn_1 small add_top_15"
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            handleUpdateProfile();
                        }}
                    >Save personal info</a>
                </p>
            </div>
            <div className="settings_panel">
                <h3>Change password</h3>
                <hr />
                <div className="form-group">
                    <label>Current Password</label>
                    <input
                        className="form-control"
                        type="password"
                        value={passwords.password}
                        onChange={(event) => {
                            setPasswords({
                                ...passwords,
                                password: event.target.value
                            })
                        }}
                    />
                </div>
                <div className="form-group">
                    <label>New Password</label>
                    <input
                        className="form-control"
                        type="password"
                        value={passwords.new_password}
                        onChange={(event) => {
                            setPasswords({
                                ...passwords,
                                new_password: event.target.value
                            })
                        }}
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                        className="form-control"
                        type="password"
                        value={passwords.confirm_password}
                        onChange={(event) => {
                            setPasswords({
                                ...passwords,
                                confirm_password: event.target.value
                            })
                        }}
                    />
                </div>
                <div id="pass-info" className="clearfix"></div>
                <p className="text-end">
                    <a
                        className="btn_1 small"
                        href="#"
                        onClick={(event) => {
                            event.preventDefault();
                            handleUpdatePassword();
                        }}
                    >
                        Save password
                    </a>
                </p>
            </div>
        </div>
    )
}

export default EditUser