import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = () => {
    const { token, user } = useSelector(({ Auth }) => Auth);

    const makeSticky = () => {
        if (window.$(window).scrollTop() > 1) {
            window.$('.header').addClass("sticky");
        } else {
            window.$('.header').removeClass("sticky");
        }
    }

    useEffect(() => {
        makeSticky();

        window.$(window).on('scroll', makeSticky);
        return () => window.$(window).off('scroll', makeSticky);
    }, [])

    useEffect(() => {
        const $ = window.$;
        $('.dropdown-user').hover(function () {
            $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeIn(300);
        }, function () {
            $(this).find('.dropdown-menu').stop(true, true).delay(50).fadeOut(300);
        });
        return () => $('.dropdown-user').off()
    }, [token, user])

    return (
        <header className="header menu_fixed">
            <div id="logo">
                <Link to="/">
                    <img src="/img/logo.svg" width="140" height="35" alt="" className="logo_normal" />
                    <img src="/img/logo_sticky.svg" width="140" height="35" alt="" className="logo_sticky" />
                </Link>
            </div>
            <ul id="top_menu">
                <li><Link to="/write-review" className="btn_top">Write a Review</Link></li>
                {/* <li><Link to="/companies-landing" className="btn_top company">For Companies</Link></li> */}
                {
                    token && user
                        ?
                        <li>
                            <div class="dropdown dropdown-user">
                                <a href="#0" class="logged" data-bs-toggle="dropdown"><img src="/img/avatar4.jpg" alt="" /></a>
                                <div class="dropdown-menu">
                                    <ul>
                                        <li><Link to="/user-dashboard">My Dashboard</Link></li>
                                        <li><Link to="/user-settings">My Settings</Link></li>
                                        <li><Link to="#0">Log Out</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        :
                        <li><Link to="/auth/login" id="sign-in" className="login" title="Sign In">Sign In</Link></li>
                }
            </ul>
            <a href="#menu" className="btn_mobile">
                <div className="hamburger hamburger--spin" id="hamburger">
                    <div className="hamburger-box">
                        <div className="hamburger-inner"></div>
                    </div>
                </div>
            </a>

            <nav id="menu" className="main-menu">
                <ul>
                    <li className="d-block d-sm-none"><span><a href="#" className="btn_top">Write a review</a></span></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header;