import CompanyReviews from '../../components/CompanyReviews';
import CompanyReviewSummary from '../../components/CompanyReviewSummary';
import Footer from '../../components/Footer';
import StickyHeader2 from '../../components/StickyHeader2';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCompanyAsync } from '../../actions/company.action';
import { getCompanyReviewsAsync } from '../../actions/review.action';
import useQuery from '../../utils/useQuery';
import ReviewCardWide from '../../components/ReviewCardWide';

function CompanyReview() {
  const initialState = {
    company: null,
    rating_counts: {},
    reviews: [],
    last_page: 1,
    page: 1,
    offset: 0,
    highlighted: null
  };
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  const qs = useQuery();
  const highlight = qs.get('highlight') || '';

  useEffect(() => {
    setLoaded(false);
    setState(initialState);
    console.log(slug)
    dispatch(getCompanyAsync(slug, false, (data) => {
      setState({
        ...state,
        ...data,
      })
      setLoaded(true);
    }))
  }, [slug])

  useEffect(() => {
    if (loaded)
      dispatch(getCompanyReviewsAsync(slug, highlight, page, 20, (data) => {
        const { highlighted, pagination: { data: reviews, last_page, page, offset } } = data;
        console.log(highlighted)
        setState({
          ...state,
          reviews,
          last_page,
          page,
          offset,
          highlighted
        })
      }))
  }, [loaded, page])

  if (!state.company) return null

  return (
    <>
      <StickyHeader2 />
      <main>
        <CompanyReviewSummary state={state} />
        <CompanyReviews setPage={setPage} state={state} />
      </main>
      <Footer />
    </>
  );
}

export default CompanyReview;
