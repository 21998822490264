import * as types from './general.types'
import { generalService } from '../services'
import { showErrorMessage } from '../utils'

export const getLandingDataAsync = (
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_LANDING_DATA.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await generalService.getLandingData()
      dispatch({ type: types.GET_LANDING_DATA.SUCCESS, payload: _data })
      successCallback?.()
    } catch (err) {
      dispatch({ type: types.GET_LANDING_DATA.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
