import React from "react";
import { Link } from "react-router-dom";
import RatingStars from "../RatingStars";

const CompaniesResults = ({ state, handleLoadMore, actions = ['read-more'] }) => {
    const { companies, end } = state;

    return (
        <div className="container margin_35_35">
            <div className="isotope-wrapper">
                {
                    companies.map((company, index) => {
                        return (
                            <div className="company_listing isotope-item high">
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="company_info">
                                            <figure>
                                                <Link to={`/company/${company.slug}`}>
                                                    <img src={company.logo_url || 'https://via.placeholder.com/294x189/FFFFFF/000000?text=' + company.name} alt="" />
                                                </Link>
                                            </figure>
                                            <h3>{company.name}</h3>
                                            <p>{company.description}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="text-center float-lg-end">
                                            <span className="rating">
                                                <strong>Based on {company.rating_count} reviews</strong>
                                                <RatingStars rating={company.avg_rating} />
                                            </span>
                                            {
                                                actions.includes('read-more')
                                                ?
                                                <Link to={`/company/${company.slug}`} className="btn_1 small">Read more</Link>
                                                :
                                                null
                                            }
                                            {
                                                actions.includes('write-review')
                                                ?
                                                <Link to={`/write-review/${company.slug}`} className="btn_1 small">Write review</Link>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            {
                !end
                    ?
                    <p className="text-center">
                        <Link
                            to="#"
                            onClick={(event) => {
                                event.preventDefault();
                                handleLoadMore()
                            }} className="btn_1 rounded add_top_15"
                        >
                            Load more
                        </Link>
                    </p>
                    :
                    null
            }
        </div>
    )
}

export default CompaniesResults
