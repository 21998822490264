import React from "react";

const SupportSection = () => {
    return (
        <div className="bg_color_1">
            <div className="container margin_tabs">
                <div id="tabs" className="tabs">
                    <nav>
                        <ul>
                            <li>
                                <a href="#" onClick={(event) => event.preventDefault()}>
                                    <i className="pe-7s-help1"></i>
                                    Questions<em>Omnis justo gloriatur et sit</em>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className="content">
                        <section id="section-1">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div id="message-contact"></div>
                                    <form method="post" action="assets/contact.php" id="contactform"
                                        autocomplete="off">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group required">
                                                    <input className="form-control" type="text" id="name_contact"
                                                        name="name_contact" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group required">
                                                    <input className="form-control" type="text" id="lastname_contact"
                                                        name="lastname_contact" placeholder="Last Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group required">
                                                    <input className="form-control" type="email" id="email_contact"
                                                        name="email_contact" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group required">
                                                    <input className="form-control" type="text" id="phone_contact"
                                                        name="phone_contact" placeholder="Telephone" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group required">
                                            <textarea className="form-control" id="message_contact"
                                                name="message_contact" style={{ height: 150 }}
                                                placeholder="Message"></textarea>
                                        </div>
                                        <div className="form-group required">
                                            <input className="form-control" type="text" id="verify_contact"
                                                name="verify_contact" placeholder="Are you human? 3 + 1 =" />
                                        </div>
                                        <div className="form-group add_top_30 text-center">
                                            <input type="submit" value="Submit" className="btn_1 rounded"
                                                id="submit-contact" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportSection
