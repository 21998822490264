import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import NiceSelect from "../NiceSelect";

const ReviewsSearch = ({ state, category, query, setQuery }) => {
    const { categories } = useSelector(({ Category }) => Category)
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div id="results">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-3 col-md-4 col-10">
                        <h1><strong>{state.total}</strong> result found for query</h1>
                    </div>
                    <div className="col-xl-5 col-md-6 col-2">
                        <a href="#0" className="search_mob btn_search_mobile"></a>
                        <div className="row g-0 custom-search-input-2 inner">
                            <div className="col-lg-7">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search reviews for a company"
                                        value={query}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <i className="icon_search"></i>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <NiceSelect className="wide" value={category} onChange={(value) => {
                                    searchParams.set('category', value);
                                    setSearchParams(searchParams);
                                }}>
                                    <option value="">All Categories</option>
                                    {
                                        categories.map((category, index) => <option key={index} value={category.slug}>{category.title}</option>)
                                    }
                                </NiceSelect>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search_mob_wp">
                    <div className="custom-search-input-2">
                        <div className="form-group">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search reviews..."
                                value={query}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <i className="icon_search"></i>
                        </div>
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="Where" />
                            <i className="icon_pin_alt"></i>
                        </div>
                        <NiceSelect className="wide" value={category} onChange={(value) => {
                            searchParams.set('category', value);
                            setSearchParams(searchParams);
                        }}>
                            <option value="">All Categories</option>
                            {
                                categories.map((category, index) => <option key={index} value={category.slug}>{category.title}</option>)
                            }
                        </NiceSelect>
                        <input type="submit" value="Search" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewsSearch;
