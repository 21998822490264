import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLandingDataAsync } from '../../actions/general.action';
import CallSection from '../../components/CallSection';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import LatestReviews from '../../components/LatestReviews';
import TopCategories from '../../components/TopCategories';

function LandingPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLandingDataAsync(() => {
      console.log("Homepage data loaded")
    }))
  }, []);

  return (
    <>
      <Header />
      <main>
        <Hero />
        <TopCategories />
        <LatestReviews />
        <CallSection />
      </main>
      <Footer />
    </>
  );
}

export default LandingPage;
