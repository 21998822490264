import React, { useEffect, useRef } from "react";
import '../../utils/jquery'
import 'jquery-nice-select';

const NiceSelect = ({ children, value, onChange }) => {
    const selectWrapperRef = useRef(null);

    useEffect(() => {
        if (selectWrapperRef.current) {
            const $wrapper = window.$(selectWrapperRef.current);
            $wrapper.find('select').niceSelect()
            const _onChange = () => {
                onChange?.($wrapper.find('select').val())
            }
            $wrapper.on('change', 'select', _onChange)
            return () => {
                $wrapper.off('change', 'select', _onChange)
                $wrapper.find('select').niceSelect('destroy')
            }
        }
    }, [children, selectWrapperRef])

    return (
        <div ref={selectWrapperRef} style={{ width: '100%' }}>
            <select className="wide" value={value} onChange={onChange}>
                {children}
            </select>
        </div>
    )
}

export default NiceSelect
