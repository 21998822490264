import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const TopCategories = () => {
    const { topSixCategories } = useSelector(({ General }) => General.LandingPage)

    return (
        <div className="container margin_60_35">
            <div className="main_title_3">
                <h2>Top Categories</h2>
                <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                <Link to="/categories">View all</Link>
            </div>
            <div className="row justify-content-center">
                {
                    topSixCategories.map((category, index) => {
                        return (
                            <div className="col-lg-4 col-sm-6" key={index}>
                                <Link to={`/reviews?category=${category.slug}`} className="grid_item">
                                    <figure>
                                        <img src={category.featured_image || 'https://via.placeholder.com/480x320/FFFFFF/000000?text=' + category.title} alt={category.title} />
                                        <div className="info">
                                            <small>{category._count.companies} Results</small>
                                            <em><i className="icon-comment"></i> {category._count.reviews} Reviews</em>
                                            <h3>{category.title}</h3>
                                        </div>
                                    </figure>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TopCategories
