import React from "react";

const RatingAvg = ({count, total, label}) => {
    const avg = total === 0 ? "0" : Math.round(count / total * 100).toString();

    return (
        <div className="row">
            <div className="col-lg-9 col-9">
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: avg + '%' }} aria-valuenow={avg} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div className="col-lg-3 col-3 text-end"><strong>{label}</strong></div>
        </div>
    )
}

export default RatingAvg;
