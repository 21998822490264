import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer';
import ReviewForm from '../../components/ReviewForm';
import StickyHeader2 from '../../components/StickyHeader2';
import { showErrorMessage } from '../../utils';
import { getReviewAsync } from '../../actions/review.action';

function EditReview() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [review, setReview] = useState(null);
  const [company, setCompany] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const match = slug.match(/(.*)-(\d+$)/)
    if (!match) {
      showErrorMessage("Invalid slug");
      navigate('/user-dashboard');
      return;
    }
    const [_, companySlug, reviewId] = match;
    console.log(_, companySlug, reviewId)
    dispatch(getReviewAsync(reviewId, (review) => {
      console.log("Company data loaded", review)
      setCompany(review.company)
      setReview(review)
    }))
  }, [slug])

  if (!company) return null;

  return (
    <>
      <StickyHeader2 />
      <main className="margin_main_container">
        <div className="container margin_60_35">
          <div className="row">
            <ReviewForm review={review} company={company} />
            <div className="col-lg-4">
              <div className="box_general company_info">
                <h3>{company.name}</h3>
                <p>{company.description}</p>
                <p><strong>Address</strong><br />{company.address || "-"}</p>
                <p><strong>Website</strong><br /><a target="_blank" href={company.website || "#"}>{company.website || "-"}</a></p>
                <p><strong>Email</strong><br /><a href={company.website ? `mailto:${company.contact_email}` : "#"}>{company.contact_email}</a></p>
                <p><strong>Telephone</strong><br />{company.telephone || "-"}</p>
              </div>
            </div>
            {/* <RecentReviews /> */}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default EditReview;
