import * as types from './company.types'
import { companyService } from '../services'
import { showErrorMessage } from '../utils'

export const getCompaniesAsync = (
  query,
  category,
  cursor,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_COMPANIES.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await companyService.getCompanies(query, category, cursor)
      dispatch({ type: types.GET_COMPANIES.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_COMPANIES.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}

export const getCompanyAsync = (
  slug,
  brief = false,
  successCallback,
  errorCallback
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_COMPANY.REQUEST })
    try {
      const {
        data: { data: _data },
      } = await companyService.getCompany(slug, brief)
      dispatch({ type: types.GET_COMPANY.SUCCESS, payload: _data })
      successCallback?.(_data)
    } catch (err) {
      dispatch({ type: types.GET_COMPANY.FAILURE, payload: err })
      console.log('act err', err)
      showErrorMessage(err)
      errorCallback?.()
    }
  }
}
