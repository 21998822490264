import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, useNavigate } from "react-router-dom";

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const { token } = useSelector(({ Auth }) => Auth);

    useEffect(() => {
        if (!token) navigate('/auth/login')
    }, [token]);

    return (
        <React.Fragment>
            {
                token ? props.children : null
            }
        </React.Fragment>
    );
}

export default ProtectedRoute;