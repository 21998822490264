import React from "react";
import { Link } from "react-router-dom";

const CallSection = () => {
    return (
        <div className="call_section_3">
            <div className="wrapper">
                <div className="container clearfix">
                    <div className="col-lg-5 col-md-7 float-end">
                        <h3>Let's Help You</h3>
                        <p>
                            Vanno is a review platform open to everyone. Share your experiences to help others make
                            better choices, and help companies up their game. Our mission is to bring people and
                            companies together to create experiences for everyone.
                        </p>
                        <p>
                            <Link to="/auth/login" className="btn_1 add_top_10">
                                Join Vanno Now!
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallSection