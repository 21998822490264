import React from "react";
import '../../utils/jquery'
import moment from "moment";
import { getRatingText, getUserShortName } from "../../utils";
import RatingStars from "../RatingStars";
import { Link } from "react-router-dom";

const ReviewCardWide = ({ review }) => {
    if (!review) return null;

    const username = getUserShortName(review.author)

    return (
        <div className="review_card">
            <div className="row">
                <div className="col-md-2 user_info">

                    <figure><Link style={{ color: 'inherit' }} to={`/user/${review.author.id}`}><img src={review.author.avatar_url || 'https://via.placeholder.com/400/FFFFFF/000000?text=' + username} alt="" /></Link></figure>
                    <h5><Link style={{ color: 'inherit' }} to={`/user/${review.author.id}`}>{username}</Link></h5>

                </div>
                <div className="col-md-10 review_content">
                    <div className="clearfix add_bottom_15">
                        <span className="rating">
                            <RatingStars rating={review.rating} />
                            <em>{getRatingText(review.rating)}</em>
                        </span>
                        <em>Published {moment(review.created_at).fromNow()}</em>
                    </div>
                    <h4>"{review.title}"</h4>
                    <p>{review.review}</p>
                </div>
            </div>
            {/* <div className="row reply">
                <div className="col-md-2 user_info">
                    <figure><img src="img/avatar.jpg" alt="" /></figure>
                </div>
                <div className="col-md-10">
                    <div className="review_content">
                        <strong>Reply from Good Electronics</strong>
                        <em>Published 3 minutes ago</em>
                        <p><br />Hi Monika,<br /><br />Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod scaevola sea. Et nec tantas accusamus salutatus, sit commodo veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius essent fuisset ut. Viderer petentium cu his. Tollit molestie suscipiantur his et.<br /><br />Thanks</p>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default ReviewCardWide