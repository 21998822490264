import DeleteUser from '../../components/DeleteUser';
import EditUser from '../../components/EditUser';
import Footer from '../../components/Footer';
import StickyHeader2 from '../../components/StickyHeader2';
import UserSummary from '../../components/UserSummary';

function UserSettings() {
  return (
    <>
      <StickyHeader2 />
      <main>
        <UserSummary />
        <div className="container margin_60_35">
          <div className="row">
            <EditUser />
            <DeleteUser />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default UserSettings;
