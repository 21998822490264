import AboutHero from '../../components/AboutHero';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import OurValues from '../../components/OurValues';
import PassionAndGoal from '../../components/PassionAndGoal';

function AboutUs() {
  return (
    <>
      <Header />
      <main>
        <AboutHero />
        <PassionAndGoal />
        <OurValues />
        {/* <CallSection /> */}
      </main>
      <Footer />
    </>
  );
}

export default AboutUs;
