import Footer from '../../components/Footer';
import StickyHeader2 from '../../components/StickyHeader2';
import UserGuide from '../../components/UserGuide';
import UserReviews from '../../components/UserReviews';
import UserSummary from '../../components/UserSummary';

function UserDashboard() {
  return (
    <>
      <StickyHeader2 />
      <main>
        <UserSummary />
        <div className="container margin_60_35">
          <div className="row">
            <UserReviews />
            <UserGuide />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default UserDashboard;
