import React from "react";
import { getRatingText } from "../../utils";
import RatingAvg from "../RatingAvg";
import RatingStars from "../RatingStars";

const CompanyReviewSummary = ({ state }) => {
    const { company, rating_counts } = state;

    return (
        <div className="reviews_summary">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <figure>
                                <img  src={company.logo_url || 'https://via.placeholder.com/294x189/FFFFFF/000000?text=' + company.name} alt={company.name} />
                            </figure>
                            <small>{company.category.title}</small>
                            <h1>{company.name}</h1>
                            <span className="rating">
                                <RatingStars rating={company.avg_rating} />
                                <em>{getRatingText(company.avg_rating)} - based on {company.rating_count} reviews</em>
                            </span>
                        </div>
                        <div className="col-lg-4 review_detail">
                            <RatingAvg count={rating_counts.count_5 || 0} total={company.rating_count} label="5 stars" />
                            <RatingAvg count={rating_counts.count_4 || 0} total={company.rating_count} label="4 stars" />
                            <RatingAvg count={rating_counts.count_3 || 0} total={company.rating_count} label="3 stars" />
                            <RatingAvg count={rating_counts.count_2 || 0} total={company.rating_count} label="2 stars" />
                            <RatingAvg count={rating_counts.count_1 || 0} total={company.rating_count} label="1 stars" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyReviewSummary