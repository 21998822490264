import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompanyAsync } from '../../actions/company.action';
import Footer from '../../components/Footer';
import RecentReviews from '../../components/RecentReviews';
import ReviewForm from '../../components/ReviewForm';
import StickyHeader2 from '../../components/StickyHeader2';

function WriteReview() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    dispatch(getCompanyAsync(slug, true, ({ company }) => {
      console.log("Company data loaded", company)
      setCompany(company)
    }))
  }, [slug])

  if (!company) return null;

  return (
    <>
      <StickyHeader2 />
      <main className="margin_main_container">
        <div className="container margin_60_35">
          <div className="row">
            <ReviewForm company={company} />
            <div className="col-lg-4">
              <div className="box_general company_info">
                <h3>{company.name}</h3>
                <p>{company.description}</p>
                <p><strong>Address</strong><br />{company.address || "-"}</p>
                <p><strong>Website</strong><br /><a target="_blank" href={company.website || "#"}>{company.website || "-"}</a></p>
                <p><strong>Email</strong><br /><a href={company.website ? `mailto:${company.contact_email}` : "#"}>{company.contact_email}</a></p>
                <p><strong>Telephone</strong><br />{company.telephone || "-"}</p>
              </div>
            </div>
            {/* <RecentReviews /> */}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default WriteReview;
