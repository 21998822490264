import React from "react";
import '../../utils/jquery'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useSelector } from "react-redux";
import ReviewCard from "../ReviewCardSmall";

const LatestReviews = () => {
    const { lastestFiveReviews } = useSelector(({ General }) => General.LandingPage)

    return (
        <div className="bg_color_1">
            <div className="container margin_60">
                <div className="main_title_3">
                    <h2>Latest Reviews</h2>
                    <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                    <a href="reviews-page.html">View all</a>
                </div>

                {
                    lastestFiveReviews.length > 0
                        ?
                        <OwlCarousel
                            id='recommended'
                            className='owl-carousel owl-theme'
                            loop
                            margin={0}
                            center
                            items={2}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                767: {
                                    items: 2
                                },
                                1000: {
                                    items: 3
                                },
                                1400: {
                                    items: 3
                                }
                            }}
                        >

                            {
                                lastestFiveReviews.map((review, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <ReviewCard review={review} />
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default LatestReviews