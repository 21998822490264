import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postReviewsAsync } from "../../actions/review.action";
import { showToast } from "../../utils";

const ReviewForm = ({ company, review }) => {
    const initialState = {
        title: "",
        review: "",
        rating: 0,
    };
    const [consent, setConsent] = useState(false);
    const [values, setValues] = useState(initialState);

    const dispatch = useDispatch();

    const handleRatingChange = (rating) => {
        setValues({
            ...values,
            rating: rating
        })
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (!consent) return showToast("You need to consent to post review", "error")
        dispatch(
            postReviewsAsync(
                {
                    ...values,
                    company_id: company.id
                },
                (data) => {
                    setValues(initialState);
                    setConsent(false);
                    showToast("Review submitted successfully", "success")
                },
                (error) => console.log("Error:", error),
            )
        )
    }

    useEffect(() => {
        if (!review) return
        setValues({
            title: review.title,
            review: review.review,
            rating: review.rating,
        })
    }, [review])

    return (
        <div class="col-lg-8">
            <div class="box_general write_review">
                <h1>Write a review of {company.name}</h1>
                <div class="rating_submit">
                    <div class="form-group">
                        <label class="d-block">Overall rating</label>
                        <span class="rating">
                            <input type="radio" class="rating-input" id="5_star" name="rating-input" value="5 Stars" checked={values.rating === 5} /><label for="5_star" class="rating-star" onClick={() => handleRatingChange(5)}></label>
                            <input type="radio" class="rating-input" id="4_star" name="rating-input" value="4 Stars" checked={values.rating === 4} /><label for="4_star" class="rating-star" onClick={() => handleRatingChange(4)}></label>
                            <input type="radio" class="rating-input" id="3_star" name="rating-input" value="3 Stars" checked={values.rating === 3} /><label for="3_star" class="rating-star" onClick={() => handleRatingChange(3)}></label>
                            <input type="radio" class="rating-input" id="2_star" name="rating-input" value="2 Stars" checked={values.rating === 2} /><label for="2_star" class="rating-star" onClick={() => handleRatingChange(2)}></label>
                            <input type="radio" class="rating-input" id="1_star" name="rating-input" value="1 Star" checked={values.rating === 1} /><label for="1_star" class="rating-star" onClick={() => handleRatingChange(1)}></label>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>Title of your review</label>
                    <input
                        class="form-control"
                        type="text"
                        placeholder="If you could say it in one sentence, what would you say?"
                        value={values.title}
                        onChange={(event) => setValues({
                            ...values,
                            title: event.target.value
                        })}
                    />
                </div>
                <div class="form-group">
                    <label>Your review</label>
                    <textarea
                        class="form-control"
                        style={{ height: 180 }}
                        placeholder="Write your review to help others learn about this online business"
                        value={values.review}
                        onChange={(event) => setValues({
                            ...values,
                            review: event.target.value
                        })}
                    ></textarea>
                </div>
                <div class="form-group">
                    <div class="checkboxes float-start add_bottom_15 add_top_15">
                        <label class="container_check">Eos tollit ancillae ea, lorem consulatu qui ne, eu eros eirmod scaevola sea. Et nec tantas accusamus salutatus, sit commodo veritus te, erat legere fabulas has ut. Rebum laudem cum ea, ius essent fuisset ut. Viderer petentium cu his.
                            <input type="checkbox" checked={consent} onChange={() => setConsent(!consent)} />
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <a href="#" class="btn_1 disabled" onClick={onSubmit}>Submit review</a>
            </div>
        </div>
    )
}

export default ReviewForm
