import clsx from "clsx";
import React from "react";
import { getUserShortName } from "../../utils";
import { paginate } from "../../utils/paginate";
import ReviewCardWide from "../ReviewCardWide";

const CompanyReviews = ({ state, setPage }) => {
    const { company, reviews, last_page, page } = state;

    const hightlighedAuthor = state.highlighted ? getUserShortName(state.highlighted.author) : '';

    return (
        <div className="container margin_60_35">
            <div className="row">
                <div className="col-lg-8">
                    {
                        state.highlighted
                            ?
                            <>
                                <h6 style={{ marginBottom: 15 }}>{hightlighedAuthor}'s review:</h6>
                                <ReviewCardWide review={state.highlighted} />
                                <hr style={{ borderColor: '#bbb' }} />
                            </>
                            :
                            null
                    }

                    {
                        reviews.map((review, index) => {
                            if (review.id === state.highlighted?.id) return null;
                            return <ReviewCardWide review={review} key={index} />
                        })
                    }
                    <div className="pagination__wrapper add_bottom_15">
                        <ul className="pagination">
                            <li><a href="#0" className={clsx("prev", { disabled: page === 1 })} title="previous page">&#10094;</a></li>
                            {
                                paginate(page, last_page, setPage)
                            }
                            <li><a href="#0" className={clsx("next", { disabled: page === last_page })} title="next page">&#10095;</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="box_general company_info">
                        <h3>{company.name}</h3>
                        <p>{company.description}</p>
                        <p><strong>Address</strong><br />{company.address || "-"}</p>
                        <p><strong>Website</strong><br /><a target="_blank" href={company.website || "#"}>{company.website || "-"}</a></p>
                        <p><strong>Email</strong><br /><a href={company.website ? `mailto:${company.contact_email}` : "#"}>{company.contact_email}</a></p>
                        <p><strong>Telephone</strong><br />{company.telephone || "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyReviews