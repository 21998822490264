import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerAsync } from '../../actions/auth.action';

function RegisterPage() {
  const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(registerAsync(state, () => {
      setState(initialState)
    }))
  }

  useEffect(() => {
    document.body?.classList.toggle('auth-layout', true)
    return () => document.body.classList.toggle('auth-layout', false)
  }, []);

  return (
    <>
      <div id="preloader">
        <div data-loader="circle-side"></div>
      </div>

      <div>
        <aside>
          <figure>
            <Link to="/"><img src="/img/logo_sticky.svg" width="140" height="35" alt="" className="logo_sticky" /></Link>
          </figure>
          <div className="divider"><span>Register</span></div>
          <form autoComplete="off" onSubmit={onSubmit}>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="First Name"
                name="first_name"
                value={state.first_name}
                onChange={onChange}
              />
              <i className="ti-user"></i>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={state.last_name}
                onChange={onChange}
              />
              <i className="ti-user"></i>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="email"
                value={state.email}
                onChange={onChange}
              />
              <i className="icon_mail_alt"></i>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                name="password"
                value={state.password}
                onChange={onChange}
              />
              <i className="icon_lock_alt"></i>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="password"
                placeholder="Confirm Password"
                name="confirm_password"
                value={state.confirm_password}
                onChange={onChange}
              />
              <i className="icon_lock_alt"></i>
            </div>
            <div id="pass-info" className="clearfix"></div>
            <a href="#0" className="btn_1 rounded full-width" onClick={onSubmit}>Register Now!</a>
            <div className="text-center add_top_10">Already have an acccount? <strong><Link to="/auth/login">Sign In</Link></strong></div>
          </form>
          <div className="copy">© 2021 Vanno</div>
        </aside>
      </div>
    </>
  );
}

export default RegisterPage;
