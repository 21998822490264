import React from "react";
import HeroSearch from "../HeroSearch";

const CategoryHero = () => {
    return (
        <section className="hero_single version_3">
            <div className="wrapper">
                <div className="container">
                    <h3>Search Reviews by Categories</h3>
                    <p>Check Ratings of Businesses, Read Reviews &amp; Buy</p>
                    <HeroSearch />
                </div>
            </div>
        </section>
    )
}

export default CategoryHero