import clsx from "clsx";

export const paginate = (page, last_page, setPage) => {
    let links = [], min = 1, max = page + 4;
    if (page >= 3)  min = page - 2

    if (max - min > 4) max = min + 4

    if (max >= last_page) max = last_page

    if (max - min < 4) min = max - 4

    if (min < 1) min = 1

    const handleClick = (x) => {
        return (event) => {
            event.preventDefault();
            setPage(x);
        }
    }

    for (let x = min; x <= max; x++) {
        links.push(<li><a href="#" onClick={handleClick(x)} className={clsx({ active: x === page })}>{x}</a></li>)
    }

    return links;
}