import React from "react";

const AboutHero = () => {
    return (
        <section className="hero_single office">
            <div className="wrapper">
                <div className="container">
                    <h1>About Vanno</h1>
                    <p>Vanno helps grow your business using customer reviews</p>
                </div>
            </div>
        </section>
    )
}

export default AboutHero