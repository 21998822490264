import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesAsync } from '../../actions/category.action';
import { getReviewsAsync } from '../../actions/review.action';
import Footer from '../../components/Footer';
import ReviewsResults from '../../components/ReviewsResults';
import ReviewsSearch from '../../components/ReviewsSearch';
import StickyHeader from '../../components/StickyHeader';
import useQuery from '../../utils/useQuery';

function ReviewsListing() {
  const dispatch = useDispatch()
  const [query, setQuery] = useState('')
  const [initialsMounted, setInitialsMounted] = useState(false);
  const [loadMoreTrigger, triggerLoadMore] = useState(Date.now())
  const [loaded, setLoaded] = useState(false)
  const [state, setState] = useState({
    reviews: [],
    cursor: 0,
    end: false,
    total: 0,
  })
  const qs = useQuery();
  const category = qs.get('category') || '';
  const initialSearch = qs.get('query') || '';

  const handleLoadMore = () => {
    triggerLoadMore(Date.now())
  }

  useEffect(() => {
    console.log("Page reloaded")
    dispatch(getCategoriesAsync());
  }, [])

  useEffect(() => {
    if (initialsMounted) return
    setQuery(initialSearch)
    setInitialsMounted(true)
  }, [initialSearch])

  useEffect(() => {
    dispatch(getReviewsAsync(query, category, 0, (data) => {
      console.log(data)
      setState({
        ...data,
        end: data.reviews.length === 0 || data.reviews.length === data.total
      });
      setLoaded(true);
    }))
  }, [query, category])

  useEffect(() => {
    if (!loaded) return
    dispatch(getReviewsAsync(query, category, state.cursor, (data) => {
      setState({
        ...data,
        reviews: [
          ...state.reviews,
          ...data.reviews,
        ],
        end: data.reviews.length === 0,
      })
    }))
  }, [loadMoreTrigger])

  return (
    <>
      <StickyHeader />
      <main>
        <ReviewsSearch query={query} setQuery={setQuery} state={state} category={category} />
        {/* <CompaniesFilter /> */}
        <ReviewsResults state={state} handleLoadMore={handleLoadMore} />
      </main>
      <Footer />
    </>
  );
}

export default ReviewsListing;
