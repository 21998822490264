import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../utils";
import NiceSelect from "../NiceSelect";

const HeroSearch = () => {
    const { categories } = useSelector(({ Category }) => Category);
    const [values, setValues] = useState({
        category: '',
        query: '',
    });
    const navigate = useNavigate();

    const onSubmit = (event) => {
        event.preventDefault();
        if (!values.query) return showToast('Query is empty');
        navigate(`/companies?category=${values.category}&query=${values.query}`)
    }

    return (
        <div className="row justify-content-center">
            <div className="col-lg-9">
                <form method="post" action="#" onSubmit={onSubmit}>
                    <div className="row g-0 custom-search-input-2">
                        <div className="col-lg-7">
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="What are you looking for..."
                                    value={values.query}
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            query: event.target.value
                                        })
                                    }}
                                />
                                <i className="icon_search"></i>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <NiceSelect value={values.category} onChange={(val) => {
                                setValues({
                                    ...values,
                                    category: val,
                                })
                            }}>
                                <option value=''>All Categories</option>
                                {
                                    categories.map((category, index) => <option key={index} value={category.slug}>{category.title}</option>)
                                }
                            </NiceSelect>
                        </div>
                        <div className="col-lg-2">
                            <input type="submit" value="Search" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default HeroSearch