import moment from "moment";
import React, { useCallback } from "react";
import { getRatingText, getUserShortName, showToast } from "../../utils";
import RatingStars from "../RatingStars";
import { useDispatch } from "react-redux";
import { deleteReviewsAsync } from "../../actions/review.action";
import { Link } from "react-router-dom";

const ReviewWithActions = ({ review }) => {
    const dispatch = useDispatch();

    const username = getUserShortName(review.author);

    const handleDelete = useCallback((event) => {
        event.preventDefault();
        const proceed = window.confirm("Are you sure you want to delete the review?")
        if (proceed)
            dispatch(
                deleteReviewsAsync(
                    review.id,
                    (data) => {
                        showToast("Review deleted successfully", "success")
                    },
                    (error) => console.log("Error:", error),
                )
            )
    }, [review])

    if (!review) return null;

    return (
        <div className="review_card">
            <div className="row">
                <div className="col-md-2 user_info">
                    <figure><img src="img/avatar4.jpg" alt="" /></figure>
                    <h5>Review: "{review.company.name}"</h5>
                </div>
                <div className="col-md-10 review_content">
                    <div className="clearfix add_bottom_15">
                        <span className="rating">
                            <RatingStars rating={review.rating} />
                            <em>{getRatingText(review.rating)}</em>
                        </span>
                        <em>Published: {moment(review.created_at).fromNow()}</em>
                    </div>
                    <h4>"{review.title}"</h4>
                    <p>{review.review}</p>
                    <ul>
                        <li><a href="#0" onClick={handleDelete} className="btn_delete"><i className="icon-trash"></i>Delete</a></li>
                        <li><Link to={`/edit-review/${review.company.slug}-${review.id}`}><i className="icon-edit-3"></i> Edit</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ReviewWithActions;
