import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getUserLongName } from "../../utils";

const UserSummary = () => {
    const { profile, review_count } = useSelector(({ User }) => User);

    if (!profile) return null;

    return (
        <div className="user_summary">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <figure>
                                <img src="img/avatar4.jpg" alt="" />
                            </figure>
                            <h1>{getUserLongName(profile)}</h1>
                            <span>Account Created: {moment(profile.created_at).format("DD.MM.YYYY")}</span>
                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li>
                                    <strong>{review_count?._count.id || 0}</strong>
                                    <a href="#0" className="tooltips" data-bs-toggle="tooltip" data-placement="bottom" title="Reviews written by you"><i className="icon_star"></i> Reviews</a>
                                </li>
                                {/* <li>
                                    <strong>12</strong>
                                    <a href="#0" className="tooltips" data-bs-toggle="tooltip" data-placement="bottom" title="Number of people who have read your reviews"><i className="icon-user-1"></i> Reads</a>
                                </li> */}
                                {/* <li>
                                    <strong>36</strong>
                                    <a href="#0" className="tooltips" data-bs-toggle="tooltip" data-placement="bottom" title="Number of people who found your review useful"><i className="icon_like_alt"></i> Useful</a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserSummary