import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { verifyAsync } from '../../actions/auth.action';
import base64url from "base64-url";

function VerifyPage() {
  const initialState = {
    email: '',
    code: '',
  }
  const [state, setState] = useState(initialState)
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch()

  const location = useLocation()

  const params = useParams()

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(verifyAsync(state, () => {
      setSuccess(true)
    }, () => {
      setSuccess(true)
    }))
  }

  // const encoded = base64url.escape(btoa(JSON.stringify({ email: 'jonathanfeyisola@gmail.com', code: '12345' })))
  // console.log(encoded)

  useEffect(() => {
    try {
      const json = atob(base64url.unescape(params.encoded))
      const { email, code } = JSON.parse(json)
      setState({ email, code })
    } catch (error) {
      console.log(error)
    }
  }, [location.pathname, params])

  useEffect(() => {
    document.body?.classList.toggle('auth-layout', true)
    return () => document.body.classList.toggle('auth-layout', false)
  }, []);

  return (
    <>
      <div id="preloader">
        <div data-loader="circle-side"></div>
      </div>

      <div>
        <aside>
          <figure>
            <Link to="/"><img src="/img/logo_sticky.svg" width="140" height="35" alt="" className="logo_sticky" /></Link>
          </figure>
          {
            success
              ?
              <>
                <div className="alert alert-success alert-icon mt-3">
                  <em className="icon ni ni-alert-circle"></em> <strong>Verification Successful</strong>.
                  <br />
                  Your account was successfully verified. Click on the link to login.
                </div>
                <div className="text-center add_top_10"><strong><Link to="/auth/login">Sign In</Link></strong></div>
              </>
              :
              <>
                <div className="divider"><span>Email Verification</span></div>
                <form autoComplete="off" onSubmit={onSubmit}>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={state.email}
                      onChange={onChange}
                    />
                    <i className="icon_mail_alt"></i>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Code"
                      name="code"
                      value={state.code}
                      onChange={onChange}
                    />
                    <i className="icon_lock_alt"></i>
                  </div>
                  <div id="pass-info" className="clearfix"></div>
                  <a href="#0" className="btn_1 rounded full-width" onClick={onSubmit}>Verify Email</a>
                  <div className="text-center add_top_10"><strong><Link to="/auth/login">Back to Sign In Page</Link></strong></div>
                </form>
              </>
          }

          <div className="copy">© 2021 Vanno</div>
        </aside>
      </div>
    </>
  );
}

export default VerifyPage;
