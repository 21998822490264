import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCategoriesAsync } from '../../actions/category.action';
import CategoryHero from '../../components/CategoryHero';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

function CategoryListing() {
  const dispatch = useDispatch();
  const { categories } = useSelector(({ Category }) => Category);

  useEffect(() => {
    console.log("Page reloaded")
    dispatch(getCategoriesAsync());
  }, [])

  return (
    <>
      <Header />
      <main>
        <CategoryHero />
        <div class="bg_color_1">
          <div class="container margin_60_35">
            <div class="main_title_3 text-center">
              <h2>Vanno companies categories</h2>
              <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
            </div>
            <div class="row justify-content-center">
              <div class="col-xl-10 col-lg-12">
                <div class="all_categories clearfix add_bottom_30">
                  <ul>
                    {
                      categories.map((category, index) => {
                        return <li><Link to={`/reviews?category=${category.slug}`}><strong>{category._count.reviews}</strong>{category.title}</Link></li>
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="call_section_2">
          <div class="wrapper">
            <div class="container">
              <h3>Get started now with Vanno...improve your business.</h3>
              <Link to="/auth/login" class="btn_1 medium">Join Vanno Now!</Link>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default CategoryListing;
