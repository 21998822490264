import React from "react";

const PassionAndGoal = () => {
    return (
        <>
            <div className="container margin_80">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <img alt="" src="img/office_2.jpg" className="img-fluid rounded" />
                    </div>
                    <div className="col-lg-6 pl-lg-5 pt-4">
                        <h2>Passion Drive Us</h2>
                        <p className="lead">Dolor detraxit duo in, ei sea dicit reformidans. Mel te accumsan patrioque
                            referrentur. Has causae perfecto ut, ex choro assueverit eum. Qui omnium cetero expetenda
                            no, detracto vivendum corrumpit cu duo.</p>
                        <p className="lead">Sed ne prompta insolens mediocrem.</p>
                        <p className="lead"><em>Mark Twain CEO</em></p>
                    </div>
                </div>
            </div>

            <div className="bg_color_1">
                <div className="container margin_80">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 pl-lg-5 order-lg-last">
                            <img alt="" src="img/office_3.jpg" className="img-fluid rounded" />
                        </div>
                        <div className="col-lg-6 pt-4 order-lg-first">
                            <h2>Succes is our GOAL!</h2>
                            <p className="lead">Vis at partem hendrerit, his te facete tacimates concludaturque, duo ex
                                fabulas menandri. Idque saperet assentior mea an. Nisl copiosae reformidans duo ea, no
                                doming elaboraret sed.</p>
                            <p className="lead">Quod exerci torquatos id sit, ne vix officiis consetetur. Te viris corpora
                                voluptaria mea, hendrerit prodesset no cum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PassionAndGoal
