import React from "react";
import { getUserLongName } from "../../utils";
import moment from "moment";

const UserPageBanner = ({ state }) => {
    const { user } = state;

    return (
        <div className="reviews_summary">
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column align-items-center">
                            <figure>
                                <img src="img/avatar4.jpg" alt="" />
                            </figure>
                            <h1>{getUserLongName(user)}</h1>
                            <span>Account Created: {moment(user.created_at).format("DD.MM.YYYY")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPageBanner