import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReviewWithActions from "../ReviewWithActions";
import { getUserAsync } from "../../actions/user.action";

const UserReviews = () => {
    const { reviews } = useSelector(({ User }) => User);
    const { deleted_review } = useSelector(({ Review }) => Review);
    const dispatch = useDispatch();

    // update the UI when a review is deleted
    useEffect(() => {
        if (deleted_review === null) return;
        dispatch(getUserAsync())
    }, [deleted_review])

    return (
        <div className="col-lg-8">
            {
                reviews.data.length === 0
                    ?
                    <>No records found</>
                    :
                    null
            }
            {
                reviews.data.map((review) => {
                    return (
                        <ReviewWithActions review={review} />
                    )
                })
            }
        </div>
    )
}

export default UserReviews
