
import React from "react"

const RatingStars = ({ rating }) => {
    const stars = [
        <i className="icon_star empty"></i>,
        <i className="icon_star empty"></i>,
        <i className="icon_star empty"></i>,
        <i className="icon_star empty"></i>,
        <i className="icon_star empty"></i>
    ]

    let _rating = Math.round(rating);
    while (_rating > 0) {
        stars[--_rating] = <i className="icon_star"></i>
    }

    return (
        <>
            {stars}
        </>
    )
}

export default RatingStars;
