import React from "react";
import '../../utils/jquery'
import moment from "moment";
import { getRatingText, getUserLongName, getUserShortName } from "../../utils";
import RatingStars from "../RatingStars";
import clsx from "clsx";
import { Link } from "react-router-dom";

const ReviewCardSmall = ({ review, addBottom15 }) => {
    return (
        <div className="review_listing">
            <div className={clsx("clearfix", { add_bottom_15: addBottom15 })}>
                <Link to={`/user/${review.author.id}`}>
                    <figure>
                        <img src="/img/avatar1.jpg" alt="" />
                    </figure>
                </Link>
                <span className="rating">
                    <RatingStars rating={review.rating} />
                    <em>{getRatingText(review.rating)}</em>
                </span>
                <Link style={{color: 'inherit'}} to={`/user/${review.author.id}`}><small>{getUserLongName(review.author)}</small></Link>
            </div>
            {/* <h3><Link style={{color: 'inherit'}} to={`/user/${review.author.id}`}><strong>{getUserShortName(review.author)}</strong></Link> reviewed <Link to={`/company/${review.company.slug}`}>{review.company.name}</Link></h3> */}
            <h4>"{review.title}"</h4>
            <p>{review.review}</p>
            <ul className="clearfix">
                <li><small>Published: {moment(review.created_at).format("DD.MM.YYYY")}</small></li>
                <li><Link to={`/company/${review.company.slug}?highlight=${review.id}`} className="btn_1 small">Read review</Link></li>
            </ul>
        </div>
    )
}

export default ReviewCardSmall