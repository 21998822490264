import React from "react";

const DeleteUser = () => {
    return (
        <div className="col-lg-4" id="sidebar">
            <div className="box_general">
                <h5>Delete account</h5>
                <p>At nec senserit aliquando intellegat, et graece facilisis pro. Per in ridens sensibus interesset, eos ei nonumes incorrupte, iriure diceret an eos.</p>
                {/* <a href="#" className="btn_1 small">Delete account</a> */}
            </div>
        </div>
    )
}

export default DeleteUser
