import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import CompaniesListing from './pages/CompaniesListing';
import CompanyReview from './pages/CompanyReview';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import UserDashboard from './pages/UserDashboard';
import UserSettings from './pages/UserSettings';
import WriteReview from './pages/WriteReview';
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WriteCompanySelect from './pages/WriteCompanySelect';
import Auth from './layout/Auth';
import ReviewsListing from './pages/ReviewsListing';
import CategoryListing from './pages/CategoryListing';
import Guest from './layout/Guest';
import AdminDashboard from './pages/AdminDashboard';
import EditReview from './pages/EditReview';
import VerifyPage from './pages/Verify';
import UserPage from './pages/UserPage';

const root = ReactDOM.createRoot(document.getElementById('page'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route index element={<Guest Component={LandingPage} />} />
        <Route path="/about-us" element={<Guest Component={AboutUs} />} />
        <Route path="/contact-us" element={<Guest Component={ContactUs} />} />
        <Route path="/companies" element={<Guest Component={CompaniesListing} />} />
        <Route path="/reviews" element={<Guest Component={ReviewsListing} />} />
        <Route path="/categories" element={<Guest Component={CategoryListing} />} />
        <Route path="/company/:slug" element={<Guest Component={CompanyReview} />} />
        <Route path="/user/:id" element={<Guest Component={UserPage} />} />
        <Route path="/auth/login" element={<Guest Component={LoginPage} />} />
        <Route path="/auth/register" element={<Guest Component={RegisterPage} />} />
        <Route path="/auth/verify/:encoded" element={<Guest Component={VerifyPage} />} />

        <Route path="/user-dashboard" element={<Auth Component={UserDashboard} />} />
        <Route path="/user-settings" element={<Auth Component={UserSettings} />} />
        <Route path="/write-review" element={<Auth Component={WriteCompanySelect} />} />
        <Route path="/write-review/:slug" element={<Auth Component={WriteReview} />} />

        <Route path="/edit-review/:slug" element={<Auth Component={EditReview} />} />

        <Route path="/admin-dashboard" element={<Auth Component={AdminDashboard} />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
