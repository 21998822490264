import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginAsync } from '../../actions/auth.action';

function LoginPage() {
  const initialState = {
    email: '',
    password: '',
  }
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(loginAsync(state, () => {
      setState(initialState)
      navigate('/user-dashboard')
    }))
  }

  useEffect(() => {
    document.body?.classList.toggle('auth-layout', true)
    return () => document.body.classList.toggle('auth-layout', false)
  }, []);

  return (
    <>
      <div id="preloader">
        <div data-loader="circle-side"></div>
      </div>

      <div>
        <aside>
          <figure>
            <Link to="/"><img src="/img/logo_sticky.svg" width="140" height="35" alt="" className="logo_sticky" /></Link>
          </figure>
          <form autoComplete='off'>
            <div className="divider"><span>Login</span></div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={state.email}
                onChange={onChange}
              />
              <i className="icon_mail_alt"></i>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                name="password"
                value={state.password}
                onChange={onChange}
              />
              <i className="icon_lock_alt"></i>
            </div>
            <div className="clearfix add_bottom_30">
              <div className="checkboxes float-start">
                <label className="container_check">Remember me
                  <input type="checkbox" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="float-end mt-1"><a id="forgot" href="javascript:void(0);">Forgot Password?</a></div>
            </div>
            <a href="#0" className="btn_1 rounded full-width" onClick={onSubmit}>Login to Vanno</a>
            <div className="text-center add_top_10">New to Vanno? <strong><Link to="/auth/register">Sign up!</Link></strong></div>
          </form>
          <div className="copy">© 2021 Vanno</div>
        </aside>
      </div>
    </>
  );
}

export default LoginPage;
