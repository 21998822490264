import clsx from "clsx";
import React from "react";
import { paginate } from "../../utils/paginate";
import ReviewCardWide from "../ReviewCardWide";

const UserPageReviews = ({ state, setPage }) => {
    const { reviews, last_page, page } = state;

    return (
        <div className="container margin_60_35">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    {
                        reviews.length > 0
                            ?
                            <>
                                {
                                    reviews.map((review, index) => {
                                        return <ReviewCardWide review={review} key={index} />
                                    })
                                }
                                <div className="pagination__wrapper add_bottom_15">
                                    <ul className="pagination">
                                        <li><a href="#0" className={clsx("prev", { disabled: page === 1 })} title="previous page">&#10094;</a></li>
                                        {
                                            paginate(page, last_page, setPage)
                                        }
                                        <li><a href="#0" className={clsx("next", { disabled: page === last_page })} title="next page">&#10095;</a></li>
                                    </ul>
                                </div>
                            </>
                            :
                            <div className="text-center">No reviews by user</div>
                    }


                </div>
            </div>
        </div>
    )
}

export default UserPageReviews