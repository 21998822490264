import * as types from "../actions/index";

const initialState = {
  LandingPage: {
    loading: false,
    topSixCategories: [],
    categories: [],
    lastestFiveReviews: []
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LANDING_DATA.REQUEST:
      return {
        ...state,
        LandingPage: {
          ...state.LandingPage,
          loading: true
        }
      };
    case types.GET_LANDING_DATA.SUCCESS:
      return {
        ...state,
        LandingPage: {
          ...state.LandingPage,
          ...payload,
          loading: false,
        }
      };
    case types.GET_LANDING_DATA.FAILURE:
      return {
        ...state,
        LandingPage: {
          ...state.LandingPage,
          loading: false,
        }
      };
    default:
      return state;
  }
};
