import { useEffect, useRef } from 'react';
import Footer from '../../components/Footer';
import StickyHeader2 from '../../components/StickyHeader2';
import UserGuide from '../../components/UserGuide';
import UserReviews from '../../components/UserReviews';
import UserSummary from '../../components/UserSummary';
import '../../utils/tabs';

function AdminDashboard() {
  const tabRef = useRef(null);

  useEffect(() => {
    const tab = tabRef.current;
    if (tab)
      new window.CBPFWTabs(tab);
  }, [])

  return (
    <>
      <StickyHeader2 />
      <main>
        <UserSummary />
        <div className="container margin_60_35">
          <div className="row">
            <div className='col-lg-12'>
              <div class="bg_color_1">
                <div class="container">
                  <div class="tabs" ref={tabRef}>
                    <nav>
                      <ul>
                        <li><a href="#section-1"><i class="pe-7s-help1"></i>Questions<em>Omnis justo gloriatur
                          et sit</em></a></li>
                        <li><a href="#section-2"><i class="pe-7s-help2"></i>Support<em>Quo corrumpit
                          euripidis</em></a></li>
                      </ul>
                    </nav>
                    <div class="content">
                      <section id="section-1">
                        <div class="row justify-content-center">
                          <div class="col-lg-8">
                            <div id="message-contact"></div>
                            <form method="post" action="assets/contact.php" id="contactform"
                              autocomplete="off">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group required">
                                    <input class="form-control" type="text" id="name_contact"
                                      name="name_contact" placeholder="Name" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group required">
                                    <input class="form-control" type="text" id="lastname_contact"
                                      name="lastname_contact" placeholder="Last Name" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group required">
                                    <input class="form-control" type="email" id="email_contact"
                                      name="email_contact" placeholder="Email" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group required">
                                    <input class="form-control" type="text" id="phone_contact"
                                      name="phone_contact" placeholder="Telephone" />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group required">
                                <textarea class="form-control" id="message_contact"
                                  name="message_contact" style={{ height: 150 }}
                                  placeholder="Message"></textarea>
                              </div>
                              <div class="form-group required">
                                <input class="form-control" type="text" id="verify_contact"
                                  name="verify_contact" placeholder="Are you human? 3 + 1 =" />
                              </div>
                              <div class="form-group add_top_30 text-center">
                                <input type="submit" value="Submit" class="btn_1 rounded"
                                  id="submit-contact" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </section>
                      <section id="section-2">
                        <div class="row justify-content-center">
                          <div class="col-lg-8">
                            <a href="help.html" class="btn_support">Please first visit our Support
                              Center!</a>
                            <div id="message-support"></div>
                            <form method="post" action="assets/support.php" id="support" autocomplete="off">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group required">
                                    <input class="form-control" type="text" id="name_support"
                                      name="name_support" placeholder="Name" />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group required">
                                    <input class="form-control" type="email" id="email_support"
                                      name="email_support" placeholder="Email" />
                                  </div>
                                </div>
                              </div>

                              <div class="form-group required">
                                <textarea class="form-control" id="message_support"
                                  name="message_support" style={{ height: 150 }}
                                  placeholder="Support request"></textarea>
                              </div>
                              <div class="form-group required">
                                <input class="form-control" type="text" id="verify_support"
                                  name="verify_support" placeholder="Are you human? 3 + 1 =" />
                              </div>
                              <div class="form-group add_top_30 text-center">
                                <input type="submit" value="Submit" class="btn_1 rounded"
                                  id="submit-support" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <UserGuide /> */}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default AdminDashboard;
