import { toast } from 'react-toastify';
import cookies from "js-cookie";

export const generateActions = (action) => {
    action = action.toUpperCase();
    return {
        REQUEST: `${action}_REQUEST`,
        SUCCESS: `${action}_SUCCESS`,
        FAILURE: `${action}_FAILURE`,
    };
};

export const getUserShortName = (user) => user?.first_name || user?.last_name || ''

export const getUserLongName = (user) => {
    const first_name = user?.first_name || '';
    const last_name = user?.last_name || '';
    return `${first_name} ${last_name}`.trim()
}

export const getRatingText = (rating) => `${rating.toFixed(2)}/5.00`

export const showToast = (message, type) => {
    const props = {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
    }
    switch (type?.toLowerCase()) {
        case "success":
            toast.success(message, {
                position: "top-right",
                ...props
            });
            break;
        case "info":
            toast.info(message, {
                position: "top-right",
                ...props
            });
            break;
        case "loading":
            toast.loading(message, {
                position: "top-right",
                ...props
            });
            break;
        case "warn":
            toast.warn(message, {
                position: "top-right",
                ...props
            });
            break;
        case "error":
            toast.error(message, {
                position: "top-right",
                ...props
            });
            break;
        default:
            toast.info(message, {
                position: "top-right",
                ...props
            });
            break;
    }
};

function parseErrorMessage(data) {
    if (typeof data?.message === "string" && data?.message !== "") {
        return data.message;
    } else if (data?.message instanceof Array && data.message.length > 0) {
        return data?.message[0];
    } else if (typeof data?.error === "string" && data?.error !== "") {
        return data.error;
    } else if (data?.error instanceof Array) {
        return data?.error[0] || "An error occurred, contact the webmasters";
    } else {
        return "An error occurred, contact the webmasters";
    }
}

export const showErrorMessage = (err) => {
    const message = parseErrorMessage(err.getData ? err.getData() : null);
    return showToast(message, "error");
};

export const getAuthToken = () => cookies.get('__session');

export const setAuthToken = (value) => cookies.set('__session', value, { path: "/", expires: new Date(Date.now() + (1000 * 60 * 30)) });

export const clearAuthToken = () => cookies.remove('__session', { path: "/" });

export const logout = () => {
    clearAuthToken()
    window.location.href = "/auth/login";
};