import React, { useEffect } from "react";

const OurValues = () => {
    const showTab = (id) => {
        window.$('#' + id).addClass('active').siblings().removeClass('active');
        window.$('#' + id + '-content').addClass('show active').siblings().removeClass('show active');
    }

    const handleClick = (event) => {
        event.preventDefault();
        showTab(event.target.id);
    }

    useEffect(() => {
        showTab('tab-1')
    }, [])

    return (
        <div className="values">
            <div className="wrapper">
                <div className="container">
                    <div className="main_title_2">
                        <h2>Our Values</h2>
                        <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
                    </div>
                    <div className="row justify-content-center" style={{ minHeight: 210 }}>
                        <div className="col-lg-5">
                            <div className="nav flex-column" role="tablist" aria-orientation="vertical">
                                <a href="#" className="nav-link" id="tab-1" onClick={handleClick}>Helps consumers and companies</a>
                                <a href="#" className="nav-link" id="tab-2" onClick={handleClick}>Shoppers and retailers benefits</a>
                                <a href="#" className="nav-link" id="tab-3" onClick={handleClick}>Making e-commerce so divers</a>
                                <a href="#" className="nav-link" id="tab-4" onClick={handleClick}>Assess their service daily</a>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="tab-content">
                                <div className="tab-pane fade" id="tab-1-content">
                                    <p className="lead">
                                        Vis at partem hendrerit, his te facete tacimates concludaturque,
                                        duo ex fabulas menandri. Idque saperet assentior mea an. Nisl copiosae
                                        reformidans duo ea, no doming elaboraret sed. Malorum cotidieque an cum.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="tab-2-content">
                                    <p className="lead">
                                        Sed ne prompta insolens mediocrem, omnium fierent sed an, quod
                                        vivendo mel in. Argumentum honestatis ad mel, cu vis quot utroque. Nemore
                                        percipit no has. Mollis tincidunt his ex, dolore inimicus no cum.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="tab-3-content">
                                    <p className="lead">
                                        Quod exerci torquatos id sit, ne vix officiis consetetur. Te
                                        viris corpora voluptaria mea, hendrerit prodesset no cum. Has tota semper
                                        alterum ne, qui te suas sensibus. Duo persius sensibus ne, choro soluta
                                        adolescens vim te, sale scripta ex his.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="tab-4-content">
                                    <p className="lead">
                                        Sumo periculis inciderint ius ex. Sit te fierent probatus
                                        delicata, id mel nonumy consul oporteat. Agam tractatos te eam, iisque
                                        vulputate moderatius cu sit. Oratio complectitur contentiones nam ut, at
                                        legere maiorum fierent duo. Mel ea vero aliquid.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurValues;