import React from "react";
import { Link } from "react-router-dom";

const UserGuide = () => {
    return (
        <div className="col-lg-4">
            <div className="box_general general_info">
                <h3>Delete a review<i className="pe-7s-help1"></i></h3>
                <p><strong>Mucius doctus constituto pri at.</strong> At vix utinam corpora, ea oblique moderatius usu. Vix id viris consul honestatis, an constituto deterruisset consectetuer pro quo corrumpit euripidis...<br /><strong><Link to="/faq">Rear more</Link></strong></p>
                <hr />
                <h3>Post a review<i className="pe-7s-help1"></i></h3>
                <p>Dolor detraxit duo in, ei sea dicit reformidans. Mel te accumsan patrioque referrentur. Has causae perfecto ut, ex choro assueverit eum...<br /><strong><Link to="/faq">Rear more</Link></strong></p>
                <hr />
                <h3>Approve a review<i className="pe-7s-help1"></i></h3>
                <p>Sed ne prompta insolens mediocrem, omnium fierent sed an, quod vivendo mel in. Argumentum honestatis ad mel, cu vis quot utroque...<br /><strong><Link to="/faq">Rear more</Link></strong></p>
                <hr />
                <div className="text-center"><Link to="/faq" className="btn_1 small">View al Faq</Link></div>
            </div>
        </div>
    )
}

export default UserGuide
